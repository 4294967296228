<template>
  <v-card class="mb-7">
    <v-card-text class="pa-5">
      <div class="d-sm-flex flex-column align-center justify-space-between">
        <div class="ml-3 d-flex justify-space-between w-100">
          <div>
            <h2 class="font-weight-regular title">
              {{ section.nombre }}
            </h2>
            <h6 class="body-2 mb-1 subtitle-2 font-weight-light">
              Datos de registrados
            </h6>
          </div>
        </div>
        <v-spacer></v-spacer>
      </div>
      <!-- Table -->
      <v-data-table
          :headers="headerArray"
          :items="itemArray"
          :options.sync="options"
          :loading="loading"
          :disable-pagination="true"
          hide-default-footer
      >
        <template v-slot:item="{item, matriculaIndex}">
          <tr>
            <td>
              <div class="d-flex" style="cursor: pointer;">
                <h4 class="font-weight-medium text-no-wrap">
                  {{ item.usuario }}
                </h4>
              </div>
            </td>
            <td>
              <div class="d-flex" style="cursor: pointer;">
                <h4 class="font-weight-medium text-no-wrap">
                  {{ item.correo }}
                </h4>
              </div>
            </td>
            <td v-for="(complement, complementIndex) in item.complements" :key="complementIndex">
              <div class="d-flex justify-center">
                <v-checkbox
                    v-model="complement.active"
                    @click="complementToggle(item, matriculaIndex, complement.id, complementIndex)"></v-checkbox>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
      >
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import InfiniteLoading from 'vue-infinite-loading'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    InfiniteLoading,
  },
  name: "TheSectionDetail",
  data() {
    return {
      loading: false,
      page: 1,
      matriculasBySection: [],
      pageSize: 20,
      options: {},
      next: true,
      sectionSelectedId: null,
      section: {
        nombre: ''
      },
      complements: [],
      firstLoad: true
    }
  },
  computed: {
    ...mapState(['searchGlobal']),
    headerArray() {
      const header = [
        {text: 'Nombre del alumno', value: 'usuario', align: 'start', sortable: false},
        {text: 'Correo', value: 'correo', align: 'start', sortable: false}
      ]
      this.complements.map(complement => {
        header.push({
          text: complement.nombre,
          value: complement.nombre,
          align: "center",
          sortable: false
        })
      })
      return header
    },
    itemArray() {
      const items = []
      this.matriculasBySection.map(matricula => {
        let item = {
          id: matricula.id,
          usuario: matricula.usuario,
          correo: matricula.correo,
          complements: []
        }
        this.complements.forEach(complement => {
          item.complements.push({
            ...complement,
            active: !!matricula.complementos.find((userComplement) => userComplement === complement.id)
          })
        })
        if (!items.filter(el => el.id === item.id).length) {
          items.push(item)
        }
      })
      return items
    }
  },
  watch: {
    async searchGlobal () {
      if (!this.firstLoad) {
        await this.$router.replace(`/dashboard/sections/${this.$route.params.id}/?search=${this.searchGlobal}&page=${this.page}`)
      }
    },
    '$route.query': {
      handler () {
        this.matriculasBySection = []
        this.$refs.InfiniteLoading.stateChanger.reset()
      },
      deep: true,
    }
  },
  methods: {
    ...mapMutations(['SET_SEARCH']),
    async infiniteHandler($state) {
      const matriculasResponse = await this.fetchMatriculasBySection()
      if (matriculasResponse.next) {
        this.page += 1;
        this.matriculasBySection.push(...matriculasResponse.results)
        $state.loaded();
      } else {
        this.matriculasBySection.push(...matriculasResponse.results)
        $state.complete();
      }
    },
    async fetchSection() {
      const sectionId = this.$route.params.id
      const response = await axios.get(`api/admin/secciones/${sectionId}`)
      this.section = response.data
    },
    async fetchComplementsBySection() {
      const sectionId = this.$route.params.id
      const response = await axios.get(`api/admin/secciones/${sectionId}/complementos/`)
      this.complements = response.data
    },
    async fetchMatriculasBySection() {
      // const sectionId = this.$route.params.id
      let response = []
      if (this.searchGlobal) {
        response = await axios.get(`api/admin/secciones/${this.$route.params.id}/matriculas/?search=${this.searchGlobal}&page_size=${this.pageSize}&page=${this.page}`)
      } else {
        response = await axios.get(`api/admin/secciones/${this.$route.params.id}/matriculas/?page_size=${this.pageSize}&page=${this.page}`)
      }
      return response.data
    },
    async complementToggle(matricula, matriculaIndex, complementId, complementoIndex) {
      try {
        const sectionId = this.$route.params.id
        const payload = new FormData()
        payload.append('matricula', matricula.id)
        payload.append('complemento', complementId)
        const response = await axios.post(`api/admin/secciones/${sectionId}/complemento_usuario/`, payload)

        // replace response object in matriculas by section array
        Object.assign(this.matriculasBySection, matriculaIndex, response.data)
      } catch (e) {
        // if request fail return to its previous value
        await this.$root.$confirm({
          title: 'Mensaje',
          message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.'
        })
        matricula.complements[complementoIndex].active = !matricula.complements[complementoIndex].active
      }
    }
  },
  async created () {
    await this.fetchSection()
    await this.fetchComplementsBySection()
    if (this.$route.query.search !== '') {
      await this.SET_SEARCH(this.$route.query.search)
    }
    if (this.$route.query.page) {
      this.page = this.$route.query.page
    }
    await this.$refs.InfiniteLoading.stateChanger.reset()
    this.firstLoad = false
  }
}
</script>

<style scoped>

</style>
